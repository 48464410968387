<template>
  <div>
    <el-card shadow="always">
      <el-image style="width: 400px; height: 400px" :src="base64Image" fit="fill" />
    </el-card>
    <el-card shadow="always">
      <el-button @click="handleLogin()" size="mini" :disabled="disabled">
        扫码确认后点击
      </el-button>
    </el-card>
    <el-card shadow="always">
      {{ loginMessage }}
    </el-card>
    <el-card shadow="always">
      <el-button @click="handleLoginState()" size="mini" :disabled="disabled">
        查询登陆状态
      </el-button>
    </el-card>
    <el-card shadow="always">
      {{ loginStateMessage }}
    </el-card>
  </div>
</template>

<script>
import { getQRCode, isOnline, wechatLogin } from "../../../api/wechat-bot";

export default {
  name: "WechatBotQRCode",
  created() {
    this.getQRCode();
  },
  data() {
    return {
      imageUrl: "",
      base64Image:"",
      disabled: false,
      loginMessage: "",
      loginStateMessage: ""
    };
  },
  methods: {
    getQRCode() {
      getQRCode().then(result => {
        this.imageUrl = result.message;

        this.getImageAsBase64(this.imageUrl)
      });
    },
    getImageAsBase64(imageUrl) {
      this.getBase64FromUrl(imageUrl)
          .then((base64Data) => {
            this.base64Image = base64Data;
          })
          .catch(() => {
            console.error("获取二维码失败");
          });
    },
    getBase64FromUrl(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function () {
          reject(new Error("Failed to fetch image"));
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },
    handleLogin() {
      wechatLogin().then(() => {
        this.disabled = true;
        this.handleLoginState();
      });
    },
    handleLoginState() {
      isOnline().then(result => {
        const online = result.data;

        if (online === true) {
          this.loginMessage = "登陆成功";
          this.loginStateMessage = "登陆成功";
        } else {
          this.loginMessage = "登陆失败，请刷新页面后重试";
          this.loginStateMessage = "登陆失败，请刷新页面后重试";
        }
      });
    }
  }
};
</script>

<style scoped></style>
