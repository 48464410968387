import request from "@/utils/request";

export function getAllWechatBot() {
  return request({
    url: "/wechat-bot/getAll",
    method: "Get"
  });
}

export function isOnline() {
    return request({
        url: "/wechat-bot/isOnline",
        method: "Get"
    });
}

export function deleteWechatBot(params) {
  return request({
    url: "/wechat-bot/delete",
    method: "Delete",
    params: params
  });
}

export function addWechatBot(requestBody) {
  return request({
    url: "/wechat-bot/add",
    method: "Post",
    data: requestBody
  });
}

// 编辑
export function editWechatBot(requestBody) {
    return request({
        url: "/wechat-bot/edit",
        method: "Post",
        data: requestBody
    });
}

export function getQRCode() {
  return request({
    url: "/wechat-bot/getQRCode",
    method: "Get"
  });
}

export function wechatLogin() {
  return request({
    url: "/wechat-bot/wechatLogin",
    method: "Get"
  });
}

